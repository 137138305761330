import usePaging from '@shapegames/utils/lib/hooks/use-paging';
import { ArticlesApi, ArticlesGroupsResponse } from 'src/services/articles';

async function getArticleGroups(nextPageCursor?: string) {
  const articlesApi = new ArticlesApi();
  try {
    const { data } = await articlesApi.getArticleGroups(nextPageCursor);
    return data;
  } catch (e) {
    throw e.message;
  }
}

export default function usePagedArticleGroups(
  articleGroups: ArticlesGroupsResponse,
) {
  return usePaging<ArticlesGroupsResponse, string | undefined>(
    (_, currPageData) => {
      return currPageData?.paging.hasMore ? currPageData?.paging.next : null;
    },
    getArticleGroups,
    articleGroups,
  );
}
