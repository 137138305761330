import ArticlesGroup from '../containers/ArticlesGroup/Item/ArticlesGroup';
import styled from 'styled-components';
import { ArticlesApi } from 'src/services/articles';
import { InferGetStaticPropsType } from 'next';
import { withLayout } from 'src/components/Layout';
import withGlobalData from 'src/hocs/with-global-data';
import {
  PAGE_REVALIDATE_TIME,
  WEBSITE_DESCRIPTION,
  WEBSITE_TITLE,
} from 'src/constants/global';
import usePagedArticleGroups from 'src/containers/ArticlesGroup/hooks/use-paged-article-groups';
import { getArticleGroupLayoutByIndex } from 'src/containers/ArticlesGroup/helpers';
import InfinityLoading from 'src/components/InfinityLoading';

export const getStaticProps = withGlobalData(async () => {
  const articlesApi = new ArticlesApi();
  const { data: articleGroups } = await articlesApi.getArticleGroups();
  return {
    props: {
      articleGroups,
      title: WEBSITE_TITLE,
      description: WEBSITE_DESCRIPTION,
    },
    revalidate: PAGE_REVALIDATE_TIME,
  };
});

function Home({
  articleGroups,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const { data, fetchMore, isLoading } = usePagedArticleGroups(articleGroups);

  const hasMore = data?.[data.length - 1].paging.hasMore;

  const listItems = data.map(d => d.items).flat();

  if (!articleGroups) {
    return <div>something went wrong</div>;
  }

  return (
    <FeedCss>
      <InfinityLoading
        onFetchMore={fetchMore}
        isLoading={isLoading}
        shouldFetchMore={hasMore}
      >
        {listItems.map((ag, idx) => {
          return (
            <ArticlesGroup
              key={`article-groupd-${idx}`}
              layout={getArticleGroupLayoutByIndex(idx)}
              title={ag.title}
              items={ag.articles.items}
              slug={ag.slug}
            />
          );
        })}
      </InfinityLoading>
    </FeedCss>
  );
}

export default withLayout(Home);

const FeedCss = styled.div`
  & > section:not(:last-child) {
    margin-bottom: ${p => p.theme.sizes.spacing8};
  }
`;
